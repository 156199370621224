import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"set-black px-5"},[_c(VRow,{attrs:{"justify":"center"}},[_c('h1',[_vm._v("Oljaca Blog Main Page - Home Page")])]),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{attrs:{"justify":"center mt-5"}},[_c('h2',[_c('u',[_vm._v("About the Blog")])])]),_c(VRow,{staticClass:"mt-5",class:{
          'px-15': !_vm.showCondensedVersion,
          'px-5': _vm.showCondensedVersion,
        },attrs:{"justify":"center"}},[_c('h2',[_vm._v(" The Oljaca Blog is a place to discover information that can benefit you and people that you know. ")])]),_c(VRow,{staticClass:"mt-5",class:{
          'px-15': !_vm.showCondensedVersion,
          'px-5': _vm.showCondensedVersion,
        },attrs:{"justify":"center"}},[_c('h2',[_c('u',[_vm._v("Topics Covered")])])]),_c(VRow,{staticClass:"mt-5",class:{
          'px-15': !_vm.showCondensedVersion,
          'px-5': _vm.showCondensedVersion,
        },attrs:{"justify":"center"}},[_c('h2',[_c('ul',{staticClass:"text-start"},[_c('li',[_vm._v(" Internet Money "),_c('ul',[_c('li',[_vm._v("Software Engineering")]),_c('li',[_vm._v(" Side Income "),_c('ul',[_c('li',[_vm._v("Investing")]),_c('li',[_vm._v("Indie Hacking")]),_c('li',[_vm._v("Affiliate Marketing")]),_c('li',[_vm._v("Blogging")]),_c('li',[_vm._v("...more")])])])])])])])])],1),_c(VCol,[_c(VRow,{attrs:{"justify":"center mt-5"}},[_c('img',{attrs:{"src":"logo.webp","alt":"","height":"200"}})]),_c(VRow,{attrs:{"justify":"center"}},[_c('h2',[_vm._v("About the Author")])]),_c(VRow,{staticClass:"my-5",attrs:{"justify":"center"}},[_c(VBtn,{staticClass:"bg-gold",on:{"click":function($event){return _vm.takeTo('https://www.instagram.com/andrej_developer/')}}},[_c(VIcon,[_vm._v("mdi-instagram")])],1)],1),_c(VRow,{class:{
          'px-15': !_vm.showCondensedVersion,
          'px-5': _vm.showCondensedVersion,
        },attrs:{"justify":"center"}},[_c('h2',{staticClass:"text-center"},[_vm._v(" Andrej is a software engineer with a year of professional experience. He broke into the field before finishing college and less than a year after he started his self-taught journey. In fact he is still in college. He is also an indie-hacker (makes micro-SaaS for profit) and finds a passion in building side income outside of his career. He also has a wide variety of life experiences and has learned many lessons that will be shared on this blog ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }