import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.min.css";
import Meta from 'vue-meta';
Vue.use(Meta, {
  keyName: 'metaInfo', 
  attribute: 'data-vue-meta', 
  ssrAttribute: 'data-vue-meta-ssr', 
  tagIDKeyName: 'vmid'
});
Vue.use(Vuetify);

export default new Vuetify({});
