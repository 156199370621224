

































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Developer Blog - Confidence - Imposter Syndrome",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Software Engineering blog post that discusses losing and gaining confidence in the software developer world as well as imposter syndrome. Read it now here",
        },
      ],
    };
  },
})
export default class Income2 extends Vue {
  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
