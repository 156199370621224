













































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Software Engineering Blog - Ultimate Self Taught Guide",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Software Engineering blog post that discusses the ultimate self-taught guide to learning web development aka software engineering. Read it now here!",
        },
      ],
    };
  },
})
export default class Income2 extends Vue {
  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
