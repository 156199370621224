




























































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title: "Oljaca Developer Blog - Self Taught vs BootCamp vs College",
      meta: [
      { name: 'description', content: 'Oljaca Software Engineering blog post that discusses the self-taught, bootcamp, and college route that are used to break into the software engineering field. Read it now here'},
    ]
    };
  },
})
export default class Income2 extends Vue {
  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
