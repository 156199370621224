




















































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Software Engineering Blog - Oljaca Side Income Blog - Oljaca Investing Blog | Andrej Oljaca | Oljaca Blog",
      meta: [
        {
          name: "description",
          content:
            "Software engineering, side income, and investing blog brought to you by software developer Andrej Oljaca whom broke into the field without a computer science or other college degree",
        },
      ],
    };
  },
})
export default class Body extends Vue {
  // Sites -> Mental Health, Money, College, Style, Music, Investing
  // Maybe have a coding logo if you focus on that or vice versa for other things
  // Current way to try to make money -> blogging -> then youtube video (reading, how to become SE, leetcode answers... think of more) -> then look up more side hustles for SE
  // What do you have to offer? Info on how to become a software engineer... investing...
  // Make sure to have youtube and insta and othere things you want to use ready and linked and same with your other websites

  // How to delete git branch / rest of git
  takeTo(url: string) {
    window.open(url, "_blank")!.focus();
  }
  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
