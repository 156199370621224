















































































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Blog - Investing | Andrej Oljaca",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Investing blog post that discusses investing, how and why (important). Read it now here",
        },
      ],
    };
  },
})
export default class Income2 extends Vue {}
