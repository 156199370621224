

































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title: "Oljaca Developer Blog - Embedded Systems - Timing Diagrams",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Software Engineering blog post that discusses embedded systems and more specifically timing diagrams. Read it now here",
        },
      ],
    };
  },
})
export default class Income2 extends Vue {
  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
