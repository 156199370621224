








































































































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Developer Blog - Learning Web Development",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Software Engineering blog post that discusses how to learn web development aka software development / software engineering. Read it now here",
        },
      ],
    };
  },
})
export default class Income2 extends Vue {}
