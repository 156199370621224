





























import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Side Income Blog - Affiliate Marketing",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Side Income blog post that discusses making money off of affiliate marketing. Read it now here",
        },
      ],
    };
  },
})
export default class Income2 extends Vue {
  get showCondensedVersion(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
