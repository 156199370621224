import Vue from "vue";
import ExternalFacingView from "../components/shared/ExternalFacingView.vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../components/shared/MainPage/Body.vue";
import SoftwareEngineeringMainPage from "../components/Blogs/SoftwareEngineering/MainPageSE.vue";
import SoftwareEngineeringDirectory from "../components/Blogs/SoftwareEngineering/Directory.vue";
import InvestingMainPage from "../components/Blogs/Investing/InvestingMainPage.vue";
import InvestingStrategy from "../components/Blogs/Investing/InvestingStrategy.vue";
import SideIncomeMain from "../components/Blogs/SideIncome/SideIncomeMain.vue";
import ESMain from "../components/Blogs/SoftwareEngineering/ES/Main.vue";
import ES1 from "../components/Blogs/SoftwareEngineering/ES/1.vue";
import ES2 from "../components/Blogs/SoftwareEngineering/ES/2.vue";
import ES3 from "../components/Blogs/SoftwareEngineering/ES/3.vue";
import ES4 from "../components/Blogs/SoftwareEngineering/ES/4.vue";
import WisdomMain from "../components/Blogs/Wisdom/WisdomMain.vue";
import EBook from "../components/Blogs/SideIncome/E-Book.vue";
import MentalHealth1 from "../components/Blogs/MentalHealth/1.vue";
import MentalHealth2 from "../components/Blogs/MentalHealth/2.vue";
import MentalHealth3 from "../components/Blogs/MentalHealth/3.vue";
import MentalHealth4 from "../components/Blogs/MentalHealth/4.vue";
import MentalHealth5 from "../components/Blogs/MentalHealth/5.vue";
import MentalHealth6 from "../components/Blogs/MentalHealth/6.vue";
import MentalHealth7 from "../components/Blogs/MentalHealth/7.vue";
import MentalHealth8 from "../components/Blogs/MentalHealth/8.vue";
import MentalHealth9 from "../components/Blogs/MentalHealth/9.vue";
import MentalHealth10 from "../components/Blogs/MentalHealth/10.vue";
import MentalHealth11 from "../components/Blogs/MentalHealth/11.vue";
import MentalHealth12 from "../components/Blogs/MentalHealth/12.vue";
import Youtubes from "../components/Youtubes/Youtubes.vue";
import Social1 from "../components/Blogs/Social/1.vue";
import Social2 from "../components/Blogs/Social/2.vue";
import SocialMain from "../components/Blogs/Social/SocialMain.vue";
import MentalHealthMain from "../components/Blogs/MentalHealth/MentalHealthMain.vue";
import Ads from "../components/Blogs/SideIncome/Ads.vue";
import Blogging from "../components/Blogs/SideIncome/Blogging.vue";
import AffiliateMarketing from "../components/Blogs/SideIncome/AffiliateMarketing.vue";
import MicroSaaS from "../components/Blogs/SideIncome/MicroSaas.vue";
import LearningWebDevelopment1 from "../components/Blogs/SoftwareEngineering/LearningWebDevelopment1.vue";
import BootCampVs from "../components/Blogs/SoftwareEngineering/BootCampVs.vue";
import Ladders from "../components/Blogs/SoftwareEngineering/Ladders.vue";
import LeetCode from "../components/Blogs/SoftwareEngineering/LeetCode.vue";
import LinkedIn from "../components/Blogs/SoftwareEngineering/LinkedIn.vue";
import Confidence from "../components/Blogs/SoftwareEngineering/Confidence.vue";
import SelfTaughtGuide from "../components/Blogs/SoftwareEngineering/SelfTaughtGuide.vue";
import DAA from "../components/Blogs/SoftwareEngineering/DAA.vue";
import Resume from "../components/Blogs/SoftwareEngineering/Resume.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "Not Found",
    redirect: () => "/not-found",
  },
  {
    path: "/",
    component: ExternalFacingView,
    children: [
      // {
      //   path: "",
      //   redirect: () => ({ name: "Login" }),
      // },
      {
        path: "",
        name: "Home",
        component: Home,
      },
      // {
      //   path: "mental-health-1",
      //   name: "MentalHealth1",
      //   component: MentalHealth1,
      // },
      // {
      //   path: "mental-health-2",
      //   name: "MentalHealth2",
      //   component: MentalHealth2,
      // },
      // {
      //   path: "mental-health-3",
      //   name: "MentalHealth3",
      //   component: MentalHealth3,
      // },
      // {
      //   path: "social-main",
      //   name: "SocialMain",
      //   component: SocialMain,
      // },
      // {
      //   path: "mental-health-4",
      //   name: "MentalHealth4",
      //   component: MentalHealth4,
      // },
      // {
      //   path: "social-1",
      //   name: "Social1",
      //   component: Social1,
      // },
      // {
      //   path: "youtubes",
      //   name: "Youtubes",
      //   component: Youtubes,
      // },
      // {
      //   path: "social-2",
      //   name: "Social2",
      //   component: Social2,
      // },
      // {
      //   path: "wisdom",
      //   name: "WisdomMain",
      //   component: WisdomMain,
      // },
      // {
      //   path: "mental-health-5",
      //   name: "MentalHealth5",
      //   component: MentalHealth5,
      // },
      // {
      //   path: "mental-health-6",
      //   name: "MentalHealth6",
      //   component: MentalHealth6,
      // },
      // {
      //   path: "mental-health-7",
      //   name: "MentalHealth7",
      //   component: MentalHealth7,
      // },
      // {
      //   path: "mental-health-8",
      //   name: "MentalHealth8",
      //   component: MentalHealth8,
      // },
      // {
      //   path: "mental-health-9",
      //   name: "MentalHealth9",
      //   component: MentalHealth9,
      // },
      // {
      //   path: "mental-health-10",
      //   name: "MentalHealth10",
      //   component: MentalHealth10,
      // },
      // {
      //   path: "mental-health-11",
      //   name: "MentalHealth11",
      //   component: MentalHealth11,
      // },
      // {
      //   path: "mental-health-12",
      //   name: "MentalHealth12",
      //   component: MentalHealth12,
      // },
      // {
      //   path: "mental-health-main",
      //   name: "MentalHealthMain",
      //   component: MentalHealthMain,
      // },
      {
        path: "software-engineering-basics",
        name: "SoftwareEngineeringMainPage",
        component: SoftwareEngineeringMainPage,
      },
      {
        path: "blogging",
        name: "Blogging",
        component: Blogging,
      },
      /////
      {
        path: "embedded-systems-directory",
        name: "ESMain",
        component: ESMain,
      },
      {
        path: "embedded-systems-basics",
        name: "ES1",
        component: ES1,
      },
      {
        path: "embedded-systems-components",
        name: "ES2",
        component: ES2,
      },
      {
        path: "embedded-systems-timing-diagrams",
        name: "ES3",
        component: ES3,
      },
      {
        path: "embedded-systems-testing",
        name: "ES4",
        component: ES4,
      },
      /////
      {
        path: "ebook",
        name: "EBook",
        component: EBook,
      },
      {
        path: "investing-main-page",
        name: "InvestingMainPage",
        component: InvestingMainPage,
      },
      {
        path: "investing-strategy",
        name: "InvestingStrategy",
        component: InvestingStrategy,
      },
      {
        path: "learning-web-development",
        name: "LearningWebDevelopment1",
        component: LearningWebDevelopment1,
      },
      {
        path: "self-taught-guide",
        name: "SelfTaughtGuide",
        component: SelfTaughtGuide,
      },
      {
        path: "bootcamp-vs-college-vs-self-taught",
        name: "BootCampVs",
        component: BootCampVs,
      },
      {
        path: "software-engineering-directory",
        name: "SoftwareDirectory",
        component: SoftwareEngineeringDirectory,
      },
      {
        path: "side-income-main",
        name: "SideIncomeMain",
        component: SideIncomeMain,
      },
      {
        path: "affiliate-marketing",
        name: "AffiliateMarketing",
        component: AffiliateMarketing,
      },
      {
        path: "ads",
        name: "Ads",
        component: Ads,
      },
      {
        path: "micro-saas",
        name: "MicroSaaS",
        component: MicroSaaS,
      },
      {
        path: "best-job-board",
        name: "Ladders",
        component: Ladders,
      },
      {
        path: "linkedIn",
        name: "LinkedIn",
        component: LinkedIn,
      },
      {
        path: "leetcode",
        name: "LeetCode",
        component: LeetCode,
      },
      {
        path: "data-structures-and-algorithms",
        name: "DAA",
        component: DAA,
      },
      {
        path: "confidence",
        name: "Confidence",
        component: Confidence,
      },
      {
        path: "resume",
        name: "Resume",
        component: Resume,
      },
    ],
  },
  {
    path: "/join/:code",
    name: "Join",
    redirect: (route: any) => ({
      name: "Join Meeting by Code",
      params: { code: route.params.code },
      query: { passcode: route.query.passcode },
    }),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});
router.beforeEach(async (to, from, next) => {
  // const script = document.createElement("script");
  // script.src = "https://udbaa.com/bnr.php?section=General&pub=616219&format=300x250&ga=g"
  // script.type = "text/javascript";
  // document.head.appendChild(script);
  //

  // const script2 = document.createElement("script");
  // script.src = "https://udbaa.com/slider.php?section=General&pub=616219&ga=g&side=random"
  // script.type = "text/javascript";
  // document.head.appendChild(script2);

  // const script3 = document.createElement("script");
  // script.src = "https://vdbaa.com/pup.php?section=General&pt=2&pub=616219&ga=g"
  // script.type = "text/javascript";
  // document.head.appendChild(script3);
  
  next()
  // if ads then reload logic below 

  // if (localStorage.getItem("reloaded")) {
  //   // The page was just reloaded. Clear the value from local storage
  //   // so that it will reload the next time this page is visited.
  //   localStorage.removeItem("reloaded");
  // } else {
  //   // Set a flag so that we know not to reload the page twice.
  //   localStorage.setItem("reloaded", "1");
  //   location.reload();
  //   //test
  // }
});

export default router;
