



































































































import { Component, Vue } from "vue-property-decorator";
@Component({
  metaInfo() {
    return {
      title:
        "Oljaca Software Engineering Blog - Main Page",
      meta: [
        {
          name: "description",
          content:
            "Oljaca Software Engineering main page - directory to all of the Oljaca Blog software engineering blog posts. Easily roam through all posts. Check it out!",
        },
      ],
    };
  },
})
export default class Income extends Vue {
  blogs = [
    {
      img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
      id: "1",
      filters: ["Learning"],
      route: "SoftwareEngineeringMainPage",
      about: "Basics / General / How To's",
    },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Learning"],
    //   route: "ESMain",
    //   about: "Embedded Systems",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["BootCampVs"],
    //   route: "BootCampVs",
    //   about: "BootCamp vs Self Taught vs College",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Job Board"],
    //   route: "Ladders",
    //   about: "Ladders... the best job board",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Confidence"],
    //   route: "Confidence",
    //   about: "Losing confidence",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Resume"],
    //   route: "Resume",
    //   about: "Making your Resume",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Resume"],
    //   route: "LeetCode",
    //   about: "LeetCode",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Resume"],
    //   route: "DAA",
    //   about: "Data Structures and Algorithms",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Resume"],
    //   route: "LinkedIn",
    //   about: "LinkedIn",
    // },
    // {
    //   img: "https://st2.depositphotos.com/2904097/5667/v/950/depositphotos_56670849-stock-illustration-vector-coding-icon.jpg",
    //   id: "1",
    //   filters: ["Resume"],
    //   route: "SelfTaughtGuide",
    //   about: "Self Taught Guide",
    // },
  ];
  pageSize = 6;
  listCount = 0;
  searchText = "";
  selectedFilters = [];
  filters = ["Introduction"];
  dialog = false;
  page = 1;
  historyList: any[] = [];
  get filteredBlogs(): any[] {
    let blogs = this.blogs.filter((b) =>
      b.about.toLowerCase().includes(this.searchText.toLowerCase())
    );
    let filteredBlogs: any[] = [];
    if (this.selectedFilters.length) {
      blogs.forEach((b) => {
        this.selectedFilters.forEach((f) => {
          if (b.filters.includes(f)) {
            filteredBlogs.push(b);
          }
        });
      });
      return filteredBlogs;
    }
    return blogs;
  }
  updateAfterSearch() {
    this.initPage();
    this.updatePage(this.page);
  }
  randomIntFromInterval(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  created() {
    this.initPage();
    this.updatePage(this.page);
  }
  updatePage(pageIndex: number) {
    // m.title.toLowerCase().startsWith(this.searchText)
    let blogs = this.blogs.filter((b) =>
      b.about.toLowerCase().includes(this.searchText.toLowerCase())
    );
    let filteredBlogs: any[] = [];
    if (this.selectedFilters.length) {
      blogs.forEach((b) => {
        this.selectedFilters.forEach((f) => {
          if (b.filters.includes(f)) {
            filteredBlogs.push(b);
          }
        });
      });
    } else {
      filteredBlogs = blogs;
    }
    let start = (pageIndex - 1) * this.pageSize;
    let end = pageIndex * this.pageSize;
    this.historyList = filteredBlogs.slice(start, end);
    this.page = pageIndex;
  }

  initPage() {
    let blogs = this.blogs.filter((b) =>
      b.about.toLowerCase().includes(this.searchText.toLowerCase())
    );
    let filteredBlogs: any[] = [];
    if (this.selectedFilters.length) {
      blogs.forEach((b) => {
        this.selectedFilters.forEach((f) => {
          if (b.filters.includes(f)) {
            filteredBlogs.push(b);
          }
        });
      });
    } else {
      filteredBlogs = blogs;
    }
    this.listCount = filteredBlogs.length;
    if (this.listCount < this.pageSize) {
      this.historyList = filteredBlogs;
    } else {
      this.historyList = filteredBlogs.slice(0, this.pageSize);
    }
  }
  takeTo(route: string): void {
    // let routeData = this.$router.resolve({ name: route });
    // window.open(routeData.href, "_blank");
    this.$router.push({name: route})
  }

  reverseVideos() {
    this.blogs = this.blogs.reverse();
    this.updateAfterSearch();
  }

  makeVideosRandom() {
    let placeholderVideos = [...this.blogs];
    let currentIndex = placeholderVideos.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [placeholderVideos[currentIndex], placeholderVideos[randomIndex]] = [
        placeholderVideos[randomIndex],
        placeholderVideos[currentIndex],
      ];
    }
    this.blogs = placeholderVideos;
    this.updateAfterSearch();
  }
  get pages() {
    if (this.pageSize == null || this.listCount == null) return 0;
    return Math.ceil(this.listCount / this.pageSize);
  }
}
